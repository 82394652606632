// src/components/HeroSection.jsx
import React from "react";

const HeroSection = () => {
  return (
    <section className="text-center py-14 bg-gray-100 text-gray-800" id="top">
      <div className="mx-auto max-w-[1200px]">
        <h1 className="text-4xl sm:text-5xl font-bold mb-5">
          Рейтинг Форекс брокеров 2024
        </h1>
        <p className="text-lg sm:text-xl mt-2 max-w-2xl mx-auto">
          PrivateFinance поможет вам выбрать надежного брокера для уверенной торговли на рынке Форекс. Доверьтесь нашему опыту в подборе лучших брокеров.
        </p>
      </div>
    </section>
  );
};

export default HeroSection;
