import React, { useState } from 'react';

const FAQSection = () => {
    const faqs = [
        {
          question: "Что такое Форекс брокер?",
          answer: "Брокером называется компания, которая предоставляет своим клиентам доступ для торговли на финансовом рынке. Наиболее крупные брокерские компании предлагают возможность осуществлять торговлю валютными парами, акциями, сырьем, ценными бумагами, криптовалютами, контрактами на разницу цен (CFD)."
        },
        {
          question: "Кто может открыть счет у Форекс брокера?",
          answer: "Каждый совершеннолетний человек, независимо от места его проживания и гражданства, может стать клиентом брокера. Для этого необходимо будет пройти простую процедуру регистрации, открыть свой торговый счет, пополнить его любым удобным способом."
        },
        {
          question: "Как пополнить свой счет у Форекс брокера?",
          answer: "Это зависит от условий конкретной брокерской компании. Но, как правило, у клиентов проблем с доступностью ввода/вывода денежных средств не возникает. Крупные компании, предоставляющие посреднические услуги, уже давно адаптировались под потребности клиентов из разных стран. Для транзакций доступны переводы с карточек различных банков, использование популярных электронных платежных систем. Для пополнения трейдеру следует перейти в соответствующий раздел в личном кабинете и следовать инструкциям, которые прописаны брокером."
        },
        {
          question: "Что такое торговый терминал?",
          answer: "Торговым терминалом или платформой называется специально разработанный программный комплекс, при помощи которого трейдер получает доступ к валютному рынку. Терминалы размещаются на сайтах брокеров и могут быть скачены на ПК или смартфон на безвозмездной основе. Как правило, каждая крупная брокерская компания предоставляет своим клиентам несколько видов подобных платформ, снабженным специальным функционалом. Например, встроенными индикаторами технического анализа."
        },
        {
          question: "Кто такие участники валютного рынка?",
          answer: `Всех участников рынка можно разделить на три группы:
							<ol>
								<li><strong>Поставщики ликвидности.</strong> Сюда относятся крупные финансовые образования (например, банки).</li>
								<li><strong>Брокеры – посредники,</strong> предоставляющие простым трейдерам возможность осуществлять торговлю на валютном рынке.</li>
								<li><strong>Трейдеры</strong> – все участники валютного рынка, которые осуществляют куплю/продажу валют, сырья и других активов, используя функционал, предоставляемый брокерскими компаниями.</li>
							</ol>`
        },
        {
          question: "Какими торговыми терминалами может воспользоваться трейдер?",
          answer: "На сегодняшний день существует несколько видов торговых терминалов: стандартные (МТ4 и МТ5 для ПК), мобильные (адаптированы под Android и iOS), веб-терминалы (не требуют скачивания, предоставляются брокерами на безвозмездной основе)."
        },
        {
          question: "Что такое демо-счет и в чем его отличие от реального счета?",
          answer: "Демонстрационный счет – это отличный вариант для новичка, который учится торговле или же для трейдера, желающего проверить эффективность новой стратегии. Главное отличие демо-счета заключается в том, что торговля будет осуществляться виртуальными деньгами. В остальном же разницы нет: те же котировки, тот же функционал и возможности. Различия могут быть лишь в психологическом восприятии."
        },
        {
          question: "С какой минимальной суммы можно торговать на Форексе?",
          answer: "Как правило, брокеры идут навстречу своим клиентам, максимально снизив стартовую сумму депозита. В зависимости от конкретных условий компании данный параметр варьируется от 10 до 100 долларов. Также отдельно стоит сказать о наличии центовых счетов, торговля на которых возможна от 1 доллара."
        },
        {
          question: "Почему у разных Форекс брокеров отличаются котировки?",
          answer: "Следует сразу понять, что Форекс является внебиржевым рынком. Соответственно, понятие единой рыночной цены отсутствует. Маркет-мейкеры имеют право предлагать собственные цены на покупку и продажу конкретного актива. Естественно, что брокерские компании, которые, в свою очередь, выступают в качестве клиентов у маркет-мейкеров, стараются предоставлять трейдерам максимально выгодные торговые условия, но при этом они имеют ограниченный набор контрагентов. Соответственно, и выбор котировок при этом будет ограниченным. Но в любом случае разница в котировках у различных брокеров будет минимальной."
        },
        {
          question: "В чем заключаются отличия платформ МТ4 и МТ5?",
          answer: "Терминал МТ5 является усовершенствованной версией привычного терминала МТ4. Сохранив удобство и функционал своего предшественника, пятая платформа была максимально адаптирована под современные компьютеры и мобильные устройства. Например, она прекрасно себя зарекомендовала для использования на Виндовс 10. Также МТ5 был дополнен рядом полезных функций, в том числе касающихся и технического анализа. Если в МТ4 в стандартном наборе было прошито 30 индикаторов, то в пятой версии уже на 8 больше. Впрочем, если имеющихся осцилляторов вам не хватает, то всегда можно дополнить их перечень собственными помощниками."
        },
        {
          question: "Как можно объяснить ситуацию, когда сделка закрылась по стоп-приказу, но цена до него при этом не дошла?",
          answer: "Здесь следует сразу уяснить, что на валютном рынке существует две цены: Bid и Ask, то есть цена покупки и, соответственно, продажи. К примеру, если у вас была открыта сделка на продажу, то она открывалась по цене Bid, а вот закрываться будет по цене Ask. Имейте в виду, что в стандартных настройках платформы МТ4 отображается только лишь линия, соответствующая цене Bid, а стоп сработает по цене Ask, которая является невидимой."
        },
        {
          question: "Что такое советник и стоит ли им пользоваться?",
          answer: "Советником называется специально написанная программа, в которой реализуется конкретная торговая стратегия. Установив в терминал советник, трейдер может вообще не участвовать в процессе торгов или просто периодически контролировать работу своего автоматизированного помощника, отключая его, например, перед важными фундаментальными публикациями. Но здесь сразу стоит сказать о проблеме выбора: пусть вас не обманывает обилие подобных роботов в свободном доступе. Среди них очень трудно выбрать действительно прибыльного советника, который в долгосрочной перспективе будет показывать стабильный профит. Пользоваться или нет – это решение должен будет принять сам трейдер. Но мы в свою очередь просто дадим рекомендацию: проверяйте робота в тестере на истории или на демо-счете, чтобы не рисковать реальными деньгами."
        },
        {
          question: "Что такое флэт и тренд? Чем они отличаются?",
          answer: "Это состояния валютного рынка, противоположные друг другу. Если понятие тренд означает устойчивое движение цены в одну сторону, сопровождающееся лишь незначительными откатами, то флэт – это спокойное состояние рынка, когда цена ходит в узком диапазоне между границами визуально распознаваемого коридора. В большинстве случаев основной причиной возникновения флэтовой ситуации служит уменьшение торговых объемов (такое часто наблюдается между сессиями)."
        },
        {
          question: "Нужно ли получать специализированное образование для торговли на валютных рынках?",
          answer: "Для торговли на Форексе нет необходимости оканчивать, например, финансовый институт. Благодаря обилию информации в интернете каждый человек, способный к самообучению, может стать трейдером. Как правило, обучение складывается из нескольких этапов: изучение теоретической информации, анализа, составления собственной торговой стратегии, применения ее на практике. Многие новички сначала учатся азам торговли на демо-счете."
        },
        {
          question: "Из-за чего трейдер сливает свой депозит? Какими могут быть основные причины?",
          answer: "На самом деле существует множество причин, по которым трейдер терпит неудачу на валютном рынке. Сюда можно отнести несоблюдение мани-менеджмента, торговлю против тренда, слишком рискованную стратегию. Но, как правило, самая главная причина поражения – это психо-эмоциональный фактор. Профессиональный трейдер и шалящие нервы – понятия несовместимые. Человек должен научиться контролировать свои эмоции, не поддаваться панике, не испытывать чувство эйфории. Иными словами, торговля на валютном рынке должна превратиться в рутинную работу. Только тогда трейдера ждет успех."
        }
      ];
      
    
      const jsonLd = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
          "@type": "Question",
          "name": faq.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.answer
          }
        }))
      };  
  return (
    <section className="mt-10 px-5 faq-section">
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
      <div className="mb-5">
        <h2 className="text-2xl font-bold mb-5">
          Часто задаваемые вопросы
        </h2>
      </div>
      {/* FAQ Items */}
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </section>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-3 faq-item border-b border-gray-300 last:border-b-0">
      <h3
        className={`faq-question text-base font-bold cursor-pointer p-4 bg-gray-100 rounded transition-colors duration-300 ${
          isOpen ? 'bg-gray-200' : 'hover:bg-gray-200'
        } flex justify-between items-center`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <div className="px-1 w-4">
            <svg
            className={`w-4 h-4 transform transition-transform ${
                isOpen ? 'rotate-180' : 'rotate-0'
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 16"
            >
            <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 011 0l6.646 6.647 6.647-6.647a.5.5 0 11.708.708l-7 7a.5.5 0 01-.708 0l-7-7a.5.5 0 010-.708z"
            />
            </svg>
        </div>
      </h3>
      {isOpen && (
        <div className="faq-answer p-4 bg-white">
          <div className="text-gray-700">
            {formatAnswer(answer)}
          </div>
        </div>
      )}
    </div>
  );
};

// Helper function to format the answer text, especially for lists
const formatAnswer = (text) => {
  // Check if the text contains ordered list items
  if (/\d+\./.test(text)) {
    // Split the text by newlines or other delimiters if needed
    const items = text.split(/\d+\./).filter(Boolean).map(item => item.trim());

    return (
      <ol className="list-decimal list-inside">
        {items.map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </ol>
    );
  }

  // Otherwise, return the text as is, allowing for basic HTML tags
  return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

export default FAQSection;
