/* eslint-disable no-octal-escape */
// src/App.jsx
import React from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import BrokerCard from "./components/BrokerCard";
import FAQSection from "./components/FAQSection";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <main className="container mx-auto">
        {/* Broker Ranking */}
        <div className="my-5 px-5">
          <h2 id="top" className="text-center text-2xl font-bold">Лучшие Форекс брокеры 2024</h2>
        </div>

        {/* Broker Cards */}
        <div className="space-y-5">
          <BrokerCard
            rank="1"
            name="RoboForex"
            type="ECN/STP"
            deposit="$10"
            regulators="BFSC"
            platforms="MetaTrader 4, MetaTrader 5, R MobileTrader, R StocksTrader"
            rating={5}
            url="https://just-register.com/roboforex?source=privatefinance"
            logo='roboforex.png'
          />
          <BrokerCard
            rank="2"
            name="AMarkets"
            type="ECN/STP"
            deposit="$100"
            regulators="FSC, MISA, SVGFSA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={5}
            url="https://just-register.com/amarkets?source=privatefinance"
            logo='amarkets.png'
          />
          <BrokerCard
            rank="3"
            name="Alpari"
            type="ECN/STP"
            deposit="$50"
            regulators="MISA, НБ РБ"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={5}
            url="https://just-register.com/alpari?source=privatefinance"
            logo='alpari.png'
          />
          <BrokerCard
            rank="4"
            name="Forex Club Libertex"
            type="MM"
            deposit="$100"
            regulators="SVGFSA, НБ РБ, CySEC"
            platforms="MetaTrader 4, MetaTrader 5, Libertex"
            rating={5}
            url="https://just-register.com/fxclub?source=privatefinance"
            logo='fxclub.png'
          />
          <BrokerCard
            rank="5"
            name="AvaTrade"
            type="MM"
            deposit="$100"
            regulators="KNF, BVIFSC, ASIC, FSCA, JFSA, JFFA, FSRA, ISA, CIRO"
            platforms="MetaTrader 4, MetaTrader 5, AvaTradeGO"
            rating={4}
            url="https://just-register.com/avatrade-forex?source=privatefinance"
            logo='avatrade.png'
          />
          <BrokerCard
            rank="6"
            name="FxPro"
            type="STP"
            deposit="$100"
            regulators="FCA, CySEC, SCB"
            platforms="MetaTrader 4, MetaTrader 5, cTrader, FxPro Edge"
            rating={4}
            url="https://just-register.com/fxpro?source=privatefinance"
            logo='fxpro.png'
          />
          <BrokerCard
            rank="7"
            name="InstaForex"
            type="MM"
            deposit="$1"
            regulators="BVIFSC, CySEC, SVGFSA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={4}
            url="https://just-register.com/instaforex?source=privatefinance"
            logo='instaforex.png'
          />
          <BrokerCard
            rank="8"
            name="Deriv"
            type="STP"
            deposit="$1"
            regulators="LFSA, BVIFSC, VFSC, SVGFSA, JFSC, MFSA"
            platforms="MetaTrader 5, cTrader, Deriv X"
            rating={4}
            url="https://just-register.com/deriv?source=privatefinance"
            logo='deriv.png'
          />
          <BrokerCard
            rank="9"
            name="FBS"
            type="STP"
            deposit="$5"
            regulators="BFSC, CySEC, ASIC, FSCA"
            platforms="MetaTrader 4, MetaTrader 5, FBS Trader"
            rating={4}
            url="https://just-register.com/fbs?source=privatefinance"
            logo='fbs.png'
          />
          <BrokerCard
            rank="10"
            name="XM Group"
            type="MM"
            deposit="$5"
            regulators="BFSC, CySEC, ASIC, DFSA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={4}
            url="https://just-register.com/xm?source=privatefinance"
            logo='xm.png'
          />
          <BrokerCard
            rank="11"
            name="IC Markets"
            type="ECN"
            deposit="$200"
            regulators="ASIC, CySEC, CMA, SFSA"
            platforms="MetaTrader 4, MetaTrader 5, cTrader, TradingView"
            rating={4}
            url="https://just-register.com/icmarkets?source=privatefinance"
            logo='icmarkets.png'
          />
          <BrokerCard
            rank="12"
            name="xChief"
            type="STP/ECN"
            deposit="$10"
            regulators="MISA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={4}
            url="https://just-register.com/forexchief?source=privatefinance"
            logo='xchief.png'
          />
          <BrokerCard
            rank="13"
            name="FXOpen"
            type="ECN"
            deposit="$1"
            regulators="FCA, CySEC, ASIC"
            platforms="MetaTrader 4, MetaTrader 5, TickTrader, TradingView"
            rating={4}
            url="https://just-register.com/fxopen?source=privatefinance"
            logo='fxopen.png'
          />
          <BrokerCard
            rank="14"
            name="Admirals"
            type="STP"
            deposit="$25"
            regulators="CySEC, JSC, FCA, EFSA, ASIC, FSCA, CMA, SFSA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={4}
            url="https://just-register.com/admiralmarkets?source=privatefinance"
            logo='admirals.png'
          />
          <BrokerCard
            rank="15"
            name="OANDA"
            type="STP"
            deposit="$1"
            regulators="NFA, CFTC, ASIC, CIRO, FCA, JFSA, BVIFSC, MAS, KNF"
            platforms="MetaTrader 4, MetaTrader 5, fxTrade, TradingView"
            rating={4}
            url="https://just-register.com/oanda?source=privatefinance"
            logo='oanda.png'
          />
          <BrokerCard
            rank="16"
            name="LiteFinance"
            type="STP/ECN"
            deposit="$50"
            regulators="SVGFSA, CySEC"
            platforms="MetaTrader 4, MetaTrader 5, cTrader"
            rating={4}
            url="https://just-register.com/liteforex?source=privatefinance"
            logo='litefinance.png'
          />
          <BrokerCard
            rank="17"
            name="HF Markets"
            type="STP"
            deposit="$1"
            regulators="SVGFSA, FSCA, SFSA, FCA, DFSA, CMA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={4}
            url="https://just-register.com/hfm?source=privatefinance"
            logo='hfm.png'
          />
          <BrokerCard
            rank="18"
            name="Gerchik & Co"
            type="STP"
            deposit="$100"
            regulators="VFSC"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={4}
            url="https://just-register.com/gerchikco?source=privatefinance"
            logo='gerchikco.png'
          />
          <BrokerCard
            rank="19"
            name="NPBFX"
            type="STP"
            deposit="$10"
            regulators="SVGFSA, MISA"
            platforms="MetaTrader 4"
            rating={4}
            url="https://just-register.com/npbfx?source=privatefinance"
            logo='npbfx.png'
          />
          <BrokerCard
            rank="20"
            name="FIBO Group"
            type="STP"
            deposit="$1"
            regulators="BVIFSC"
            platforms="MetaTrader 4, MetaTrader 5, cTrader"
            rating={4}
            url="https://just-register.com/fibogroup?source=privatefinance"
            logo='fibogroup.png'
          />
          <BrokerCard
            rank="21"
            name="Grand Capital"
            type="STP/ECN"
            deposit="$100"
            regulators="SVGFSA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={4}
            url="https://just-register.com/grandcapital-forex?source=privatefinance"
            logo='grandcapital.png'
          />
          <BrokerCard
            rank="22"
            name="FXTM"
            type="ECN"
            deposit="$10"
            regulators="CySEC, FSCA, FCA, FSC, CMA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={3}
            url="https://just-register.com/forex-time?source=privatefinance"
            logo='fxtm.png'
          />
          <BrokerCard
            rank="23"
            name="Dukascopy Bank SA"
            type="ECN"
            deposit="$1000"
            regulators="FINMA"
            platforms="MetaTrader 4, JForex"
            rating={3}
            url="https://just-register.com/dukascopy-bank?source=privatefinance"
            logo='dukascopy-bank.png'
          />
          <BrokerCard
            rank="24"
            name="FreshForex"
            type="STP/ECN"
            deposit="$10"
            regulators="SVGFSA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={3}
            url="https://just-register.com/freshforex?source=privatefinance"
            logo='freshforex.png'
          />
          <BrokerCard
            rank="25"
            name="TeleTrade"
            type="STP/ECN"
            deposit="$1"
            regulators="SVGFSA, CySEC"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={3}
            url="https://just-register.com/teletrade?source=privatefinance"
            logo='teletrade.png'
          />
          <BrokerCard
            rank="25"
            name="Forex4you"
            type="MM"
            deposit="$1"
            regulators="BVIFSC, SVGFSA, НБ РБ"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={3}
            url="https://just-register.com/forex4you?source=privatefinance"
            logo='forex4you.png'
          />
          <BrokerCard
            rank="26"
            name="BCS Markets"
            type="MM"
            deposit="$1"
            regulators="SVGFSA"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={2}
            url="https://just-register.com/bks-forex?source=privatefinance"
            logo='bcsmarkets.png'
          />
          <BrokerCard
            rank="27"
            name="WorldForex"
            type="MM"
            deposit="$1"
            regulators="SVGFSA, VFSC"
            platforms="MetaTrader 4, MetaTrader 5"
            rating={2}
            url="https://just-register.com/wforex?source=privatefinance"
            logo='wforex.png'
          />
        </div>

        <section className="mt-10 px-5">
          <h2 id="types" className="text-2xl font-bold mb-5">
            Виды брокеров: классификация и основные отличия
          </h2>
          <p className="mb-5">
            А сейчас особое внимание хотелось бы уделить именно классификации
            брокеров по типам, чтобы неизвестные аббревиатуры и термины больше
            не ставили вас в тупик.
          </p>
          <ul className="list-none p-0 mb-5 space-y-3">
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Broker (Forex Broker)</strong> – это компания,
              предоставляющая своим клиентам возможность осуществления торговли
              на валютном рынке. При этом для нее характерны следующие
              преимущества:
              <ul className="list-disc pl-5 mt-2">
                <li className="text-gray-800 pl-5">Трейдеру для торговли доступны различные инструменты: валютные пары, металлы, сырье и даже криптовалюты.</li>
                <li className="text-gray-800 pl-5">Предоставление максимально доступных торговых условий. Например, кредитные плечи могут быть от 1:1 до 1:1000.</li>
                <li className="text-gray-800 pl-5">Низкий порог минимального депозита позволяет попробовать свои силы на валютном рынке людям с минимальным стартовым капиталом.</li>
              </ul>
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Bank</strong> – менее распространенный вид брокеров, основанный банками. Пожалуй, к числу преимуществ, в первую очередь, в этом случае стоит отнести надежность. Помимо стандартных регуляторов, деятельность таких брокеров контролируется центральными банками, которыми выдается лицензия на осуществление деятельности. Критерий удобства в этом случае также на высоте. Трейдер, решивший сотрудничать с банковским брокером, может осуществлять сделки прямо со своего счета, открытого в банке, а это существенно упрощает все финансовые операции. К числу недостатков можно отнести обязательное наличие крупных денежных средств, что делает недоступным данный сегмент торговли для мелких участников рынка.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>NDD (Non Dealing Desk)</strong> – под данным термином понимается брокер без дилинга, то есть компания не передает ордера поставщику ликвидности. Такие брокеры осуществляют прямой выход на валютный рынок, благодаря чему создаются наиболее приятные условия для клиентов. Прибыль такой компании формируется за счет взимаемой комиссии – спрэда.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>STP (Straight Through Processing)</strong> – подвид брокера NDD. В данном случае в качестве посредника при осуществлении торговли могут выступать как крупные финансовые учреждения, так и сами банки с аккредитацией, позволяющей вести торговлю на рынке форекс. При этом STP-брокер может одновременно сотрудничать с несколькими банками, что является гарантией полной обработки заказа клиентов.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>ECN (Electronic Communication Network)</strong> – еще один вид NDD-брокеров, который создает собственную систему торгов, где участники совершают сделки между собой, выставляя заявки. Заработком таких брокеров является комиссия за каждую сделку, совершенную клиентом. Правда спрэды в этом случае чаще всего плавают, так как напрямую зависят от предложений по покупке или продаже, которые поступают от участников торгов.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>MM (Market Maker)</strong> – пожалуй, это самый худший вариант для трейдера. Все дело в том, что подобные компании сами принимают участие в торгах, всегда отрабатывая против клиента.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>BB (Binary Broker)</strong> – брокеры, относящиеся к данному виду, позволяют своим клиентам осуществлять торговлю бинарными опционами. Здесь уже нет привычной программы МТ4, к которой мы все привыкли. Да и принцип торговли немного иной: вам неважно, сколько пунктов пройдет цена в нужном направлении. Главное – правильно определить это самое направление, и чтобы оно сохранилось на протяжении выставленного временного интервала.
            </li>
          </ul>
          <h2 id="choose" className="text-2xl font-bold mb-5 mt-10">
            Как выбрать честного и прибыльного Форекс брокера
          </h2>
          <p className="mb-5">
            Данный вопрос актуален для каждого человека, который решил попробовать свои силы на валютных рынках. И здесь халатность недопустима.
          </p>
          <p className="mb-5">
            В данном разделе мы подготовили для вас основные критерии, на которые стоит обратить внимание еще даже перед процедурой регистрации.
          </p>
          <p className="mb-5">
            Надеемся, что наши советы помогут вам с первого раза найти подходящую компанию, с которой в дальнейшем у вас сложатся приятные и профитные отношения.
          </p>

          <h3 className="text-xl font-semibold mb-3 mt-5">
            Надежность брокера
          </h3>
          <p className="mb-5">
            Данный критерий определяется в совокупности нескольких факторов: стаж работы на ниве брокерских услуг, информация о нем, размещенная в сети в свободном доступе, отзывы трейдеров.
          </p>
          <p className="mb-5">
            На сегодняшний день существуют компании, которые работают 10-20 лет, и, естественно, одно только это повышает градус доверия к ним.
          </p>
          <p className="mb-5">
            Рекомендуем также проверить возраст домена на соответствующих ресурсах, чтобы окончательно убедиться в правдивости информации. Мы ни в коем случае не говорим, что недавно открывшиеся брокеры будут обязательно мошенниками, но перестраховаться все-таки стоит.
          </p>
          <p className="mb-5">
            Лучше иметь дело со «<em>старожилами</em>», чем надеяться на честность «<em>темной лошадки</em>».
          </p>

          <h3 className="text-xl font-semibold mb-3 mt-5">
            Торговые условия
          </h3>
          <p className="mb-5">
            Даже после того, как вы определились с брокером, вас удовлетворил его стаж работы и информация в сети, не спешите.
          </p>
          <p className="mb-5">
            Детально ознакомьтесь с торговыми условиями, предоставляемыми компанией, чтобы уже в процессе торговли вас не поджидали неприятные сюрпризы
          </p>
          <ul className="list-none p-0 mb-5 space-y-3">
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Спред</strong> – данный параметр может иметь фиксированные или постоянные значения. Брокер имеет право регулировать его величину на свое усмотрение, но при этом на сайте, как правило, имеется подробная градация спрэда в зависимости от валютной пары.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Кредитное плечо</strong> – еще один немаловажный аспект для комфортной торговли. Данный параметр может варьироваться от 1:1 до 1:1000, но при этом нужно внимательно ознакомиться с имеющимися ограничениями, которые могут накладываться при торговле крупными суммами. Мы рекомендуем изначально открывать счет у того брокера, который позволяет вам в дальнейшем самостоятельно менять размер этого «<em>рычага</em>».
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Величина залога.</strong> Данный параметр важен, если трейдер предпочитает скальпинг-торговлю. При его минимальном значении можно входить в рынок, используя максимальные объемы.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Своп</strong> – это плата брокеру за то, что он переносит открытую позицию трейдера на следующие сутки. В зависимости от конкретной пары данный параметр может быть как положительным, так и отрицательным (это также должно быть прописано на официальном сайте брокера). Если же вы планируете вести среднесрочную или долгосрочную торговлю или просто пренебрегаете стоп-лоссами, предпочитая пересиживать просадку, то, возможно, стоит отдать предпочтение той компании, которая предусматривает регистрацию безсвоповых счетов.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Бонусные поощрения.</strong> Естественно, что каждый брокер заинтересован в привлечении все новых клиентов. Поэтому часто встречается возможность получения бонусных средств как при регистрации счета, так и при его последующих пополнениях (например, в честь конкретного праздника). Внимательно ознакомьтесь с новостями компании, чтобы понять, на какую щедрость можно рассчитывать в дальнейшем.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-3 mt-5">
            Скрытые условия сотрудничества
          </h3>
          <p className="mb-5">
            К сожалению, у некоторых, даже крупных брокеров, есть условия, которые не прописываются в договоре. О них трейдер узнает уже после, когда начинает вести активную торговлю.
          </p>
          <p className="mb-5">А именно</p>
          <ul className="list-none p-0 mb-5 space-y-3">
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Ограничение по количеству сделок и по времени их удержания.</strong> Это может стать серьезной проблемой для скальперов, которые за торговый день могут открывать десятки позиций. При наличии такого ограничения счет может быть просто заблокирован. Если данный параметр является для вас основополагающим, рекомендуем сразу же написать в службу поддержки и доподлинно убедиться в отсутствии подобных ограничений.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Автоматизированная торговля.</strong> Как правило, акулы брокерской сферы поощряют использование советников при торговле. Но есть и такие компании, которые всячески стремятся пресечь попытки трейдера использовать роботизированных помощников. Этот вопрос также решается посредством обращения в службу поддержки.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Условия ввода/вывода средств.</strong> В этом плане вас должны интересовать несколько пунктов: скорость обработки заявки, разнообразие используемых платежных систем, условия проведения операций в праздничные дни и т.д. И еще один нюанс: некоторые компании разрешают осуществлять вывод средств только на ту платежную систему, с которой ранее было совершено пополнение. Обратите на это особое внимание, чтобы избежать неприятных сюрпризов при дальнейшей работе.
            </li>
          </ul>
          <p className="mb-5">
            Рекомендуем потратить некоторое количество своего времени на подробное изучение деятельности брокера, у которого вы планируете открывать счет.
          </p>
          <p className="mb-5">
            И лучше поискать отзывы трейдеров, почитать об опыте их сотрудничества, которыми они делятся на специализированных форумах. Там пишут реальные люди, которым нет смысла скрывать реальные факты.
          </p>
          <h2 className="text-2xl font-bold mb-5 mt-10">
            Комиссии Форекс брокеров
          </h2>
          <p className="mb-5">
            Естественно, что брокер предоставляет вам свои услуги не безвозмездно. Ведь именно из всевозможных комиссий и складывается доход подобных компаний.
          </p>
          <p className="mb-5">
            Чтобы наличие комиссий не стало для вас неприятным сюрпризом при торговле, считаем уместным остановиться на данном вопросе более подробно.
          </p>
          <p className="mb-5">
            И в первую очередь рассмотрим факторы, влияющие на размер комиссионного сбора.
          </p>
          <p className="mb-5">
            К ним можно отнести
          </p>
          <ul className="list-none p-0 mb-5 space-y-3">
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Ликвидность конкретной валютной пары.</strong> Чем выше популярность у торгового инструмента, тем меньше комиссия по нему. Как правило, самой выгодной парой в этом случае является евро/бакс – фаворит среди трейдеров всех мастей.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Объем совершенной операции.</strong>
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Тип торгового счета.</strong>
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Текущее состояние рынка</strong> (понятно, что в период публикации важнейших экономических новостей комиссия также будет возрастать).
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Ликвидность рынка.</strong>
            </li>
          </ul>
          <h3 className="text-xl font-semibold mb-3 mt-5">
            Комиссии: основная классификация
          </h3>
          <p className="mb-5">
            Ну а теперь давайте перейдем к видам комиссий. На сегодняшний день их можно выделить несколько:
          </p>
          <ul className="list-none p-0 mb-5 space-y-3">
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Спред</strong> – это основной комиссионный сбор, который взимается с каждого трейдера. Говоря простым языком, под данным термином подразумевается разница между покупкой и продажей конкретного инструмента. В зависимости от типа счета, условий брокерской компании и конкретной валютной пары, спред может быть как фиксированным, так и плавающим.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Своп</strong> – это также распространенный вид комиссии у форекс-брокеров. Взимается он за перенос открытой сделки на следующие сутки. Как правило, такая комиссия является минимальной и не заметна при торговле. Тем более некоторые брокеры в этом плане пошли навстречу своим клиентам, предоставляя возможность регистрации безсвоповых счетов.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Плата за лот</strong> – данный вид комиссии существует не у каждого брокера. Суть такого сбора в следующем: помимо привычного спреда взимается дополнительная оплата за каждую сделку в процентном соотношении от ее объема. Как правило, встречается на счетах, где предусмотрен плавающий спред.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Комиссия за вывод средств.</strong> Ну в этом плане все понятно. Некоторые брокеры берут определенный процент от суммы за проведения транзакций. На данный момент многие брокеры все активнее вводят услугу беспроцентного вывода денежных средств. Но тут же обращаем ваше внимание на то, что комиссия платежной системы все равно будет списываться. Это не зависит от внутренней политики брокерской компании.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Платные смс.</strong> Это не совсем комиссия, а просто дополнительная услуга, от которой трейдер может отказаться в любой момент.
            </li>
          </ul>
          <h2 className="text-2xl font-bold mb-5 mt-10">
            Верификация у Форекс брокера
          </h2>
          <p className="mb-5">
            Одним из спорных вопросов, который очень часто вызывает недовольство трейдеров, является процедура верификации, которую в обязательном порядке заставляет проходить большинство крупных брокеров.
          </p>
          <p className="mb-5">
            Что это такое? Зачем нужен этот предварительный этап? Давайте разберемся в данном вопросе.
          </p>
          <p className="mb-5">
            Итак, процедурой верификации называется процесс подтверждения личности клиента брокера.
          </p>
          <p className="mb-5">
            Для этого трейдеру в соответствующем разделе необходимо прикрепить сканы паспорта и других документов (их список может изменяться в зависимости от требований безопасности конкретной компании).
          </p>
          <p className="mb-5">
            Как правило, чаще всего требуется загрузить
          </p>
          <ul className="list-none p-0 mb-5 space-y-3">
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              главную страницу паспорта с вашей фотографией;
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              страницу с адресом прописки;
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              ваше личное фото с паспортом в руке.
            </li>
          </ul>
          <p className="mb-5">
            Таким образом трейдер подтверждает, что при регистрации он указал достоверную информацию о себе, а банковская карта действительно оформлена на него.
          </p>
          <p className="mb-5">
            Естественно, что у клиентов после такого требования возникают опасения, а не попадут ли сканы документов третьи лицам, не будут ли они использованы не по назначению, не произойдет ли утечка информации.
          </p>
          <p className="mb-5">
            Вообще в случае сотрудничества с крупным брокером вероятность такого исхода практически полностью исключена, но вы можете также дополнительно перестраховаться: просто в поинте или в любом другом графическом редакторе допишите фразу типа «<em>Передается для брокера (далее наименование компании)</em>».
          </p>
          <p className="mb-5">
            Так вы и верификацию пройдете, и укажете целевое назначение документа, то есть для использования «<em>не во благо</em>» вы сделаете электронные копии абсолютно непригодными.
          </p>
          <p className="mb-5">
            Если же вы все-таки категорически против предоставления сканов документов и пытаетесь найти брокера, который не требует прохождения процедуры верификации, то спешим вас разочаровать – таких уже практически не осталось.
          </p>
          <p className="mb-5">
            Ну разве что только открывшиеся брокеры-новички.
          </p>
          <p className="mb-5">
            Но тут встает другой вопрос: стоит ли рисковать деньгами, доверяя свой депозит непроверенной компании только лишь из-за того, что вы боитесь отправлять электронные копии личных документов?
          </p>
        </section>
        <FAQSection />
        <section id="methodology" className="mt-10 px-5">
          <h2 className="text-2xl font-bold mb-5">
            Методология составления рейтинга Форекс брокеров
          </h2>
          <p className="mb-5">
            PrivateFinance применяет строгую методологию для оценки брокеров с использованием более 100 количественных и качественных критериев. Различные параметры получают индивидуальные оценки, которые влияют на общий рейтинг.
          </p>
          <p className="mb-5">
            Основные аспекты оценки включают:
          </p>
          <ul className="list-none p-0 mb-5 space-y-3">
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Регулирование и безопасность.</strong> Брокеры оцениваются на основе уровня и репутации лицензий и регуляторов, под которыми они действуют, а также соблюдения стандартов безопасности и защиты средств клиентов.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Отзывы пользователей.</strong> Анализируются отзывы и обратная связь клиентов для определения уровня их удовлетворенности, при этом отзывы тщательно проверяются на достоверность и объективность.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Торговые инструменты.</strong> Оценивается ассортимент предлагаемых активов, включая валютные пары, акции, индексы, товары и криптовалюты, а также широта и глубина доступных рынков для обеспечения разнообразия торговых возможностей.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Комиссии и сборы.</strong> Все торговые сборы и комиссии анализируются всесторонне для определения общих затрат для клиентов, при этом сравнивается прозрачность и конкурентоспособность тарифов различных брокеров.
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Торговые платформы.</strong> Брокеры оцениваются на основе разнообразия, качества и функциональности предлагаемых клиентам платформ, включая наличие дополнительных инструментов, мобильных приложений и удобства использования платформ.
            </li>  
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Поддержка клиентов и образовательные ресурсы.</strong> Оценивается доступность и качество клиентской поддержки, включая работу 24/7 и различные каналы связи, а также наличие обучающих материалов, вебинаров и аналитических обзоров для трейдеров разного уровня.
            </li>  
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#fcb900]">
              <strong>Дополнительные факторы.</strong> Учитываются популярность бренда, программы лояльности, бонусные предложения и другие уникальные услуги брокера, а также оцениваются инновации и дополнительные сервисы, предоставляемые брокером для улучшения опыта трейдинга.
            </li>  
          </ul>
          <p className="mb-5">
            Эта методология позволяет PrivateFinance предоставлять объективные и надежные рейтинги Форекс-брокеров, помогая клиентам сделать осознанный выбор в мире финансов и инвестиций.
          </p>
        </section>

        <section className="mt-10 px-5">
          <h2 className="text-2xl font-bold mb-5">
            Словарь трейдера
          </h2>
          <p className="mb-5">
          <strong>Акция</strong> – ценная бумага, которую выпускает определенная компания. Держатели акций (акционеры) имеют возможность получения пассивного дохода – дивидендов (при условии роста цены акции).
          </p>
          <p className="mb-5"><strong>Графический анализ</strong> – один из методов анализа рыночной ситуации, который помогает определить возможное движение цены актива. На валютном рынке различают множество фигур, возникающих на графике. Например, флаг, вымпел, треугольник, бабочка, виселица и т.д.</p>
          <p className="mb-5"><strong>Технический анализ</strong> – метод прогнозирования движения валютной пары и любого другого актива с применением различных технических осцилляторов.</p>
          <p className="mb-5"><strong>Фундаментальный анализ</strong> – способ проведения анализа рынка, опираясь на макроэкономические факторы, важные новости различных стран, изменения в политической ситуации.</p>
          <p className="mb-5"><strong>Алгоритмический трейдинг</strong> – метод торговли, при котором используются автоматизированные программы. Трейдер, который их применяет, называется алготрейдером.</p>
          <p className="mb-5"><strong>Бар</strong> – графический элемент, отображающий ценовое движение актива. Хранит в себе информацию, касающуюся цены открытия, закрытия, максимума и минимума. Для каждого временного интервала свои собственные бары.</p>
          <p className="mb-5"><strong>Бид</strong> – цена актива, по которой трейдер может открыть продажу в текущий момент времени.</p>
          <p className="mb-5"><strong>Биржа</strong> – централизованная система финансовой сферы, все участники которой получают возможность осуществлять торговлю ценными бумагами, облигациями и другими финансовыми инструментами.</p>
          <p className="mb-5"><strong>Бык</strong> – участник валютного рынка, который открывает сделки на покупки, ожидая роста цены актива. При этом трейдер, торгующий на понижение, называется медведем.</p>
          <p className="mb-5"><strong>Бэктест</strong> – проверка эффективности торговой системы в тестере методом прогонки по историческим данным о торгах.</p>
          <p className="mb-5"><strong>Валютная пара</strong> – инструмент валютного рынка с базовой (стоит в числителе) и валютой котирования (стоит в знаменателе). При этом стоимость одной валюты будет просчитываться в единицах другой.</p>
          <p className="mb-5"><strong>Волатильность</strong> – показатель рынка, определяющий скорость ценового движения. Для определения волатильности могут быть использованы различные индикаторы (многие из них уже имеются в торговых платформах).</p>
          <p className="mb-5"><strong>Гэп</strong> – разрыв цены. Как правило, его можно наблюдать сразу при открытии торговой недели после выходных, если в период закрытого рынка случились какие-то важные экономические события. Также гэп может образовываться посреди недели при публикации новостей повышенной важности, которые спровоцировали резкое ценовое колебание.</p>
          <p className="mb-5"><strong>Депозит</strong> – это сумма денежных средств, переведенных на торговый счет трейдера.</p>
          <p className="mb-5"><strong>Лот</strong> – объем сделки, равный 100 тыс. единиц базового актива.</p>
          <p className="mb-5"><strong>Профит</strong> – прибыль, которую получил трейдер в результате успешной (профитной) сделки.</p>
        </section>
      </main>
      <Footer />

      {/* Back to Top Button */}
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        className="fixed bottom-10 right-10 bg-orange-700 text-white p-4 rounded-full hover:bg-orange-900"
      >
        <svg
          className="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8 4.293l6.364 6.364a.5.5 0 01-.708.708L8 5.707l-5.656 5.658a.5.5 0 11-.708-.708L8 4.293z"
          />
        </svg>
      </button>
    </div>
  );
};

export default App;
