// src/components/Header.jsx
import React from "react";

const Header = () => {
  return (
    <header className="bg-white px-5 py-4 shadow-md flex items-center flex-wrap">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between wrap">
        <div className="logo">
          <a href="/">
            <img
              src="/logotype.png"
              title="PrivateFinance"
              alt="PrivateFinance Logo"
              className="max-w-[120px] mr-5"
            />
          </a>
        </div>
        <nav className="flex-grow">
          <ul className="list-none flex gap-6 flex-wrap p-0 my-2">
            <li className="inline">
              <a
                href="#methodology"
                className="no-underline text-gray-800 font-bold transition-colors duration-300 hover:text-orange-400"
              >
                Методология
              </a>
            </li>
            <li className="inline">
              <a
                href="#about"
                className="no-underline text-gray-800 font-bold transition-colors duration-300 hover:text-orange-400"
              >
                О проекте
              </a>
            </li>
            <li className="inline">
              <a
                href="#contact"
                className="no-underline text-gray-800 font-bold transition-colors duration-300 hover:text-orange-400"
              >
                Контакты
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
