// src/components/BrokerCard.jsx
import React from "react";

const BrokerCard = ({
  rank,
  name,
  type,
  deposit,
  regulators,
  platforms,
  rating,
  url,
  logo,
}) => {
  return (
    <div className="flex justify-between items-center bg-gray-100 p-6 rounded-lg shadow-md gap-2 md:gap-5 flex-col md:flex-row">
      {/* Broker Header: Rank and Logo */}
      <div className="flex justify-center items-center gap-5">
        <div className="flex items-center justify-center bg-orange-700 text-white font-bold w-8 h-8 rounded-full">
          {rank}
        </div>
        <img src={`/brokers-logo/${logo}`} title={name} alt={`${name} Logo`} className="h-16 w-16 max-w-[100px]" />
      </div>

      {/* Broker Details */}
      <div className="flex-1 text-center md:text-left mt-4 md:mt-0 broker-details">
        <h3 className="text-2xl font-semibold text-gray-800">{name}</h3>
        <p className="mt-2 text-gray-600">Тип брокера: {type}</p>
        <p className="mt-1 text-gray-600">Минимальный депозит: {deposit}</p>
        <p className="mt-1 text-gray-600">Регуляторы: {regulators}</p>
        <p className="mt-1 text-gray-600">Торговые платформы: {platforms}</p>
      </div>

      {/* Broker Rating */}
      <div className="flex flex-col items-center broker-rating mt-4 md:mt-0">
        <h4 className="text-xl font-semibold text-gray-800 mb-2">Рейтинг:</h4>
        <div className="flex text-yellow-500">
          {[...Array(rating)].map((_, index) => (
            <svg
              key={index}
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <polygon points="10,1 12.6,7.5 19.5,7.5 13.9,11.9 16.5,18.5 10,14.1 3.5,18.5 6.1,11.9 0.5,7.5 7.4,7.5" />
            </svg>
          ))}
        </div>
      </div>

      {/* Call-to-Action Button */}
      <a
        href={url}
        target="_blank"
        rel="sponsored noopener noreferrer"
        className="cta-button bg-orange-700 text-white px-6 py-3 rounded font-bold transition-colors duration-300 hover:bg-orange-900 mt-4 md:mt-0"
      >
        Перейти к брокеру
      </a>
    </div>
  );
};

export default BrokerCard;
