// src/components/Footer.jsx
import React from "react";

const Footer = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://privatefinance.biz/",
    "name": "PrivateFinance",
    "logo": "https://privatefinance.biz/logotype.png",
    "email": "info@privatefinance.biz",
    "sameAs": [
      "https://www.linkedin.com/company/privatefinancebiz/",
    ],
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Kyiv",
      "addressCountry": "UA"
    }
  }; 
  return (
    <footer className="bg-gray-800 text-white mt-10 px-5">
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
      <div className="container mx-auto py-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1 */}
          <div>
            <h2 id="about" className="text-xl font-semibold mb-4">О нас</h2>
            <p>
              PrivateFinance — ваш надежный партнер в мире финансов и
              инвестиций. Мы предоставляем актуальную информацию о лучших Форекс
              брокерах, чтобы помочь вам сделать осознанный выбор.
            </p>
          </div>
          {/* Column 2 */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Категории</h3>
            <ul>
              <li className="mb-2">
                <a
                  href="#top"
                  className="text-white hover:text-orange-400"
                >
                  Топ Форекс брокеров
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#types"
                  className="text-white hover:text-orange-400"
                >
                  Виды брокеров
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#choose"
                  className="text-white hover:text-orange-400"
                >
                  Как выбрать Форекс брокера
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#methodology"
                  className="text-white hover:text-orange-400"
                >
                  Методология
                </a>
              </li>
            </ul>
          </div>
          {/* Column 3 */}
          <div>
            <h3 id="contact" className="text-xl font-semibold mb-4">Контакты</h3>
            <ul>
              <li className="mb-2">
                Email:{" "}
                <a
                  href="mailto:info@privatefinance.biz"
                  className="text-white hover:text-orange-400"
                >
                  info@privatefinance.biz
                </a>
              </li>
              <li className="mb-2">Адрес: Киев, Оболонский проспект, 1Б</li>
            </ul>
          </div>
        </div>
        <div className="mt-10 border-t border-gray-700 pt-5 text-center">
          <p>
            &copy; 2014-2024 PrivateFinance.biz | Все права защищены.
            Копирование материалов разрешено только с указанием активной ссылки
            на первоисточник.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
